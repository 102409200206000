import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
    Grid,
    Filter,
    ActionButton,
    useFormContext,
    useFilterContext,
    useBaseAppContext,
} from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import MuiGrid from "@mui/material/Grid";
import MuiGrid2 from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import GridFilterField from "components/GridFilterField";
import { Box, Icon, IconButton, Tooltip } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import { Scheduler } from "components/Scheduler";
import { suffixValueFormatter } from "utils/muiUtils";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import FormErrorBox from "components/FormErrorBox";
import {
    columnesProducte,
    columnesRequisitRecurs,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";
import { GRID_CHECKBOX_SELECTION_FIELD } from "@mui/x-data-grid";
import { IconButtonButtonComponent } from "components/ActionReportButtonComponents";

const GridSchedulerToggle = (props) => {
    const { gridActive, setGridActive } = props;
    const { t } = useTranslation();

    return (
        <ToggleButtonGroup
            value={gridActive}
            exclusive
            onChange={(event, newValue) => setGridActive(newValue)}
            size="small"
            sx={{ ml: 2 }}
        >
            <ToggleButton value={true}>
                {t("agendesProducte.tab.taula")}
            </ToggleButton>
            <ToggleButton value={false}>
                {t("agendesProducte.tab.calendari")}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

const ActionPlanificarForm = () => {
    const { data } = useFormContext();
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="nomDisponibilitat" lg={6} />
            <MuiGrid item lg={6} />
            <GridFormField
                name="producte"
                lg={6}
                advancedSearchColumns={columnesProducte}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField
                name="requeriment"
                lg={6}
                advancedSearchColumns={columnesRequisitRecurs}
                advancedSearchMaxWidth="lg"
                fixedFilter={
                    data?.producte
                        ? `producte.id : '${data?.producte?.id}'`
                        : undefined
                }
            />
            <GridFormField name="diaInici" lg={3} />
            <GridFormField name="diaFi" lg={3} />
            <GridFormField name="horaInici" lg={3} />
            <GridFormField name="horaFi" lg={3} />
            <GridFormField name="dilluns" lg={1.7} />
            <GridFormField name="dimarts" lg={1.7} />
            <GridFormField name="dimecres" lg={1.7} />
            <GridFormField name="dijous" lg={1.7} />
            <GridFormField name="divendres" lg={1.7} />
            <GridFormField name="dissabte" lg={1.7} />
            <GridFormField name="diumenge" lg={1.7} />
        </MuiGrid>
    );
};
const ActionPlanificarGridClients = () => {
    const { t } = useTranslation();
    const { data, doFieldChange } = useFormContext();

    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "nom",
            flex: 4,
        },
        {
            name: "tipus",
            flex: 1.5,
        },
        {
            name: "preu",
            flex: 1.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];

    return (
        <FormErrorBox fieldName="clientIds">
            <Grid
                title={t("clients.title")}
                resourceName="client"
                columns={columns}
                height={"400px"}
                inlineEditable={false}
                density="compact"
                selectable
                onSelectionChange={(selectionModel) =>
                    doFieldChange("clientIds", selectionModel)
                }
                selectionModel={data?.clientIds ?? []}
                defaultSortModel={[
                    {
                        field: "codi",
                        sort: "asc",
                    },
                ]}
            />
        </FormErrorBox>
    );
};

export const ActionPlanificarContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("indicadores.common.form.tabs")}
                >
                    <Tab
                        label={t("agendesProducte.actions.planificar.tabForm")}
                        value="0"
                        // title={t("indicadores.common.form.tab.form")}
                    />
                    <Tab
                        label={t("clients.title")}
                        value={"1"}
                        // disabled={!data?.id}
                        // title={t("indicadores.common.form.tab.adjuntsTooltip")}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <ActionPlanificarForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <ActionPlanificarGridClients />
            </TabPanel>
        </TabContext>
    );
};

const ActionReassignarContent = (props) => {
    const { producte } = props;
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="canviarNom" lg={6} />
            <GridFormField name="reassignarRequeriments" lg={6} />
            <GridFormField
                name="nomDisponibilitat"
                lg={6}
                disabled={!data?.canviarNom}
            />
            <GridFormField
                name="requeriment"
                advancedSearchColumns={columnesRequisitRecurs}
                fixedFilter={`producte.id : ${producte?.id}`}
                lg={6}
                disabled={!data?.reassignarRequeriments}
            />

            <GridFormField name="reassignarClients" />
            {data?.reassignarClients && (
                <MuiGrid item sx={{ width: "100%" }}>
                    <ActionPlanificarGridClients />
                </MuiGrid>
            )}
        </MuiGrid>
    );
};

const FilterContent = (props) => {
    const { filterApiRef, setProducte } = props;
    const { t } = useTranslation();
    const { data } = useFilterContext();

    React.useEffect(() => {
        setProducte(data?.producte);
    }, [data]);

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="dataInici" xs={6} md={4} lg={2} xl={1.5} />
            <GridFilterField name="dataFi" xs={6} md={4} lg={2} xl={1.5} />
            <GridFilterField
                name="producte"
                sm={12}
                md={4}
                lg={4}
                xl={2.2}
                advancedSearchColumns={columnesProducte}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFilterField name="horaInici" xs={6} md={4} lg={2} xl={1.2} />
            <GridFilterField name="horaFi" xs={6} md={4} lg={2} xl={1.2} />
            <GridFilterField
                name="zona"
                md={4}
                lg={4}
                xl={1.5}
                advancedSearchColumns={columnesZones}
            />
            <GridFilterField
                name="tipusProducte"
                xs={6}
                md={4}
                lg={3}
                xl={1.5}
            />
            <GridFilterField
                name="actiu"
                xs={5}
                md={4}
                lg={2}
                xl={0.8}
                type="checkbox-select"
            />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, ge, le } = SpringFilterQueryBuilder;

    const filter = and(
        equal("producte.id", data?.producte?.id),
        equal("producte.zona.id", data?.zona?.id),
        equal("tipusProducte", data?.tipusProducte),
        ge("data", data?.dataInici),
        le("data", data?.dataFi),
        equal("horaInici", data?.horaInici),
        equal("horaFi", data?.horaFi),
        equal("actiu", data?.actiu?.toString())
    ).toString();

    return filter;
};

const AgendesProducteFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized, setProducte } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"AP_FILTER"}
                resourceName={"agendaProducte"}
                persistentState
                springFilterBuilder={springFilterBuilder}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                initialOnChangeRequest
            >
                <FilterContent
                    filterApiRef={filterApiRef}
                    setProducte={setProducte}
                />
            </Filter>
        </Box>
    );
};

const CalendariExtraContent = (props) => {
    const { event } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                color: "rgba(0, 0, 0, 0.6)",
            }}
        >
            <Box sx={{ display: "flex", gap: 2 }}>
                <Box>
                    <Icon fontSize="small">square</Icon>
                    <Typography
                        variant="caption"
                        title="Ocupació"
                        sx={{
                            ml: 1,
                            position: "relative",
                            top: "-5px",
                        }}
                    >
                        {event.ocupacio}
                    </Typography>
                </Box>
                <Box>
                    <Icon fontSize="small">check_box_outline_blank</Icon>
                    <Typography
                        variant="caption"
                        title="Disponibilitat"
                        sx={{
                            ml: 1,
                            position: "relative",
                            top: "-5px",
                        }}
                    >
                        {event.disponibilitat}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    my: 1,
                }}
            >
                <ActionButton
                    id={event?.event_id}
                    resourceName="agendaProducte"
                    code="AP_DUPLICAR"
                    detached
                    variant="contained"
                    formDialogSize="lg"
                    formChildren={<ActionPlanificarContent />}
                    formStaticData={{
                        nomDisponibilitat: event?.nomDisponibilitat,
                        producte: event?.producte,
                        requeriment: event?.requeriment,
                        horaInici: event?.horaInici,
                        horaFi: event?.horaFi,
                    }}
                    onSuccess={() => {
                        // gridApiRef?.current?.doRefresh();
                    }}
                />

                <IconButton
                    title={t("agendesProducte.detall.title")}
                    onClick={() => {
                        navigate(`form/${event?.event_id}`);
                    }}
                >
                    <Icon>{"info"}</Icon>
                </IconButton>
            </Box>
        </Box>
    );
};

const AgendesProducte = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const [producte, setProducte] = React.useState();
    const [gridActive, setGridActive] = React.useState(true);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const gridApiRef = React.useRef();
    const navigate = useNavigate();
    const { currentLanguage } = useBaseAppContext();

    const columns = [
        {
            name: "data",
            flex: 1,
            minWidth: 130,
        },
        {
            name: "nom",
            flex: 0.1,
            minWidth: 250,
        },
        {
            name: "producte",
            flex: 2,
            minWidth: 200,
        },
        {
            name: "horaInici",
            noSeconds: true,
            flex: 1,
            minWidth: 130,
        },
        {
            name: "horaFi",
            noSeconds: true,
            flex: 1,
            minWidth: 130,
        },
        {
            name: "countReserves",
            flex: 1,
            minWidth: 150,
        },
        {
            name: "ocupacio",
            flex: 1,
            minWidth: 120,
        },
        {
            name: "disponibilitat",
            flex: 1,
            minWidth: 120,
        },
        {
            name: "capacitat",
            flex: 1,
            minWidth: 120,
        },
        {
            name: "actiu",
            flex: 0.5,
            minWidth: 90,
        },
    ];

    const toggleComponent = (
        <GridSchedulerToggle
            gridActive={gridActive}
            setGridActive={setGridActive}
        />
    );

    React.useEffect(() => {
        gridApiRef?.current?.doSelectNone();
    }, [fixedFilter]);

    // Todo: S'ha posat aixo pq la llibreria no refresca la traduccio de l'accio sense refrescar el grid
    React.useEffect(() => {
        gridApiRef?.current?.doRefresh();
    }, [currentLanguage]);

    const toolbarAdditionalActions = () => {
        const listActions = [
            {
                position: 1,
                component: toggleComponent,
            },
            {
                position: 2,
                component: (
                    <ActionButton
                        resourceName="agendaProducte"
                        code="AP_SELECCIONAR_TOT"
                        title={t("agendesProducte.actions.seleccionar")}
                        detached
                        variant="contained"
                        icon="checklist"
                        formDialogSize="lg"
                        ignoreFields
                        confirmed
                        formStaticData={{
                            springFilter: fixedFilter,
                        }}
                        onSuccess={(params) => {
                            setSelectionModel(
                                params.map((value) => parseInt(value))
                            );
                        }}
                    />
                ),
            },
            {
                position: 2,
                component: (
                    <IconButton
                        title={t("comu.deselecionar")}
                        onClick={() => gridApiRef?.current?.doSelectNone()}
                        disabled={selectionModel?.length === 0}
                    >
                        <Icon>playlist_remove</Icon>
                    </IconButton>
                ),
            },
            {
                position: 2,
                component: (
                    <ActionButton
                        resourceName="agendaProducte"
                        code="AP_PLANIFICAR"
                        title={t("agendesProducte.actions.planificar.title")}
                        detached
                        variant="contained"
                        formDialogSize="lg"
                        buttonComponent={IconButtonButtonComponent}
                        buttonComponentProps={{ color: "red" }}
                        icon="event"
                        formChildren={<ActionPlanificarContent />}
                        onSuccess={() => {
                            gridApiRef?.current?.doRefresh();
                        }}
                    />
                ),
            },
            {
                position: 2,
                component: (
                    <ActionButton
                        code="AP_REASSIGNAR"
                        resourceName="agendaProducte"
                        multiple
                        icon="move_down"
                        disabled={producte ? false : true}
                        variant="contained"
                        formDialogSize="lg"
                        formChildren={
                            <ActionReassignarContent producte={producte} />
                        }
                        onSuccess={() => {
                            gridApiRef?.current?.doRefresh();
                        }}
                    />
                ),
            },
            {
                position: 2,
                component: (
                    <ActionButton
                        code="AP_DESHABILITAR"
                        resourceName="agendaProducte"
                        multiple
                        icon="lock"
                        variant="contained"
                        ignoreFields
                        onSuccess={() => {
                            gridApiRef?.current?.doRefresh();
                        }}
                    />
                ),
            },
            {
                position: 2,
                component: (
                    <ActionButton
                        code="AP_ESBORRAR"
                        resourceName="agendaProducte"
                        multiple
                        icon="delete"
                        variant="contained"
                        ignoreFields
                        onSuccess={() => {
                            gridApiRef?.current?.doRefresh();
                        }}
                    />
                ),
            },
        ];

        return listActions;
    };

    const rowAdditionalActions = (params) => {
        const listActions = [
            {
                icon: "search",
                label: t("comu.detall"),
                onClick: () => navigate(`form/${params?.id}`),
            },
        ];

        listActions.push({
            icon: "content_copy",
            // label: "Duplicar",
            showInMenu: true,
            rowLinkAction: "AP_DUPLICAR",
            rowLinkActionOnSuccess: () => gridApiRef?.current?.doRefresh(),
            rowLinkFormChildren: <ActionPlanificarContent />,
            rowLinkFormStaticData: {
                nomDisponibilitat: params?.nom,
                producte: params?.producte,
                requeriment: params?.requeriment,
                diaInici: params?.diaInici,
                diaFi: params?.diaFi,
                horaInici: params?.horaInici,
                horaFi: params?.horaFi,
                clientIds: params?.clientIds,
            },
            rowLinkFormDialogSize: "lg",
        });

        listActions.push({
            icon: "lock",
            showInMenu: true,
            rowLinkAction: "AP_DESHABILITAR",
        });

        listActions.push({
            icon: "lock_open",
            showInMenu: true,
            rowLinkAction: "AP_HABILITAR",
        });

        listActions.push({
            icon: "delete",
            showInMenu: true,
            rowLinkAction: "AP_ESBORRAR",
        });

        return listActions;
    };

    return gridActive ? (
        <Grid
            apiRef={gridApiRef}
            title={t("agendesProducte.title")}
            resourceName="agendaProducte"
            perspectives={["clients"]}
            columns={columns}
            fixedFilter={fixedFilter}
            formRoutePath="form"
            toolbarAdditionalActions={toolbarAdditionalActions()}
            toolbarAdditionalRow={
                <AgendesProducteFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                    setProducte={setProducte}
                />
            }
            toolbarHideCreateAction
            toolbarHideDeleteAction
            rowHideDeleteAction
            rowHideEditViewAction
            // onRowDoubleClick={() => {}}
            rowAdditionalActions={(params) => rowAdditionalActions(params.row)}
            actionExecEnabled
            findDisabled={!filterInitialized}
            density={"compact"}
            initialState={{
                pinnedColumns: {
                    left: [GRID_CHECKBOX_SELECTION_FIELD, "data"],
                    right: ["actions"],
                },
            }}
            selectable
            defaultSortModel={[
                {
                    field: "data",
                    sort: "asc",
                },
                {
                    field: "horaInici",
                    sort: "asc",
                },
            ]}
            selectionModel={selectionModel}
            onSelectionChange={setSelectionModel}
        />
    ) : (
        <Scheduler
            title={t("agendesProducte.title")}
            resourcesResourceName="producte"
            eventsResourceName="agendaProducte"
            initialDate={new Date()}
            resourceFieldsMap={{
                assignee: "id",
                text: "nom",
                subtext: "descripcio",
            }}
            eventFieldsMap={(d) => ({
                event_id: d.id,
                title:
                    d?.nom +
                    "  -  ( " +
                    d.ocupacio +
                    " / " +
                    d.disponibilitat +
                    ")",
                start: new Date(d.data + "T" + d.horaInici),
                end: new Date(d.data + "T" + d.horaFi),
                assignee: d.producte.id,
                ocupacio: d.ocupacio,
                disponibilitat: d.disponibilitat,
                ////////StaticData////////
                nomDisponibilitat: d?.nom,
                producte: d?.producte,
                requeriment: d?.requeriment,
                horaInici: d?.horaInici,
                horaFi: d?.horaFi,
            })}
            viewerExtraComponent={(fields, event) => {
                return <CalendariExtraContent event={event} />;
            }}
            toggleComponent={toggleComponent}
        />
    );
};

export default AgendesProducte;
