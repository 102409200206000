const translationCa = {
    comu: {
        si: "Si",
        no: "No",
        resetFiltre: "Esborrar filtre",
        detall: "Veure el detall",
        tancar: "Tancar",
        deselecionar: "Desseleccionar tot",
    },
    page: {
        notFound: "No trobat",
    },
    clients: {
        title: "Clients",
        create: "Crear client",
        update: "Modificar client",
    },
    zones: {
        title: "Zones",
        create: "Crear zona",
        update: "Modificar zona",
    },
    zonesUser: {
        title: "Zones d'usuari",
        create: "Crear zona d'usuari",
        update: "Modificar zona d'usuari",
    },
    users: {
        title: "Usuaris",
        create: "Crear usuari",
        update: "Modificar usuari",
        password: {
            title: "Canviar contrasenya",
            caption: "Emplenar només si es vol modificar la contrasenya de l'usuari",
        },
    },
    recursos: {
        title: "Recursos",
        create: "Crear recurs",
        update: "Modificar recurs",
    },
    reserves: {
        title: "Reserves",
        create: "Crear reserva",
        update: "Modificar reserva",
        totals: {
            title: "Totals",
            adults: "Adults",
            infants: "Infants",
            bebes: "Bebes",
            efectiu: "Efectiu",
            targeta: "Targeta",
            reserves: "Reserves",
            total: "Total",
        },
        exportarA3: "Descarregar export A3",
        reservaManual: "Aquesta reserva ha estat modificada manualment",
        liniaManual: "Alguna de les línies d'aquesta reserva han estat modificades manualment",
        reservaLiniaManual: "Tant aquesta reserva com alguna de les seves línies han estat modificades manualment",
        altres: 'Altres',
    },
    reservesDescompte: {
        title: "Descomptes de reserva",
        create: "Crear descomptes de reserva",
        update: "Modificar descomptes de reserva",
    },
    reservesLinia: {
        title: "Linies de reserva",
        create: "Crear linies de reserva",
        update: "Modificar linies de reserva",
    },
    requisitsRecurs: {
        title: "Requisits de recursos",
        create: "Crear requisits de recursos",
        update: "Modificar requisits de recursos",
    },
    requerimentsRecurs: {
        title: "Requeriments de recursos",
        create: "Crear requeriment de recursos",
        update: "Modificar requeriment de recursos",
    },
    productes: {
        title: "Productes",
        create: "Crear producte",
        update: "Modificar producte",
    },
    agendesProducte: {
        title: "Disponibilitat del producte",
        create: "Crear disponibilitat del producte",
        update: "Modificar disponibilitat del producte",
        tab: {
            taula: 'Taula',
            calendari: 'Calendari',
        },
        actions: {
            planificar: {
                title: 'Planificar',
                tabForm: 'Dades generals',
            },
            seleccionar: 'Seleccionar totes les disponibilitats',
        },
        detall: {
            title: 'Veure detall',
        },
        calendari: {
            switchProductes: "Filtrar per tots els productes",
        },
    },
    parametres: {
        title: 'Paràmetres',
        titleMobil: 'Preu Cuevas per a l\'aplicació mòbil',
        titleComptabilitat: 'Paràmetres per a comptabilitat',
    },
    informes: {
        title: 'Informes per zona i venedor',
        create: 'Crear informe',
        update: 'Modificar informe',
        totals: {
            efectiu: 'Efectiu',
            targeta: 'Targeta',
            tiquets: 'Tiquets',
            coves: 'Coves',
            total: 'Import total',
            resultats: 'Resultats',
        },
    },
};

export default translationCa;
