const translationEs = {
    comu: {
        si: "Si",
        no: "No",
        resetFiltre: "Borrar filtro",
        detall: "Ver el detalle",
        tancar: "Cerrar",
        deselecionar: "Deseleccionar todo",
    },
    page: {
        notFound: "No encontrado",
    },
    clients: {
        title: "Clientes",
        create: "Crear cliente",
        update: "Modificar cliente",
    },
    zones: {
        title: "Zonas",
        create: "Crear zona",
        update: "Modificar zona",
    },
    zonesUser: {
        title: "Zonas de usuario",
        create: "Crear zona de usuario",
        update: "Modificar zona de usuario",
    },
    reserves: {
        title: "Reservas",
        create: "Crear reserva",
        update: "Modificar reserva",
        totals: {
            title: "Totales",
            adults: "Adultos",
            infants: "Niños",
            bebes: "Bebes",
            efectiu: "Efectivo",
            targeta: "Tarjeta",
            reserves: "Reservas",
            total: "Total",
        },
        exportarA3: "Descargar export A3",
        reservaManual: "Esta reserva ha sido modificada manualmente",
        liniaManual: "Alguna de las líneas de esta reserva han sido modificadas manualmente",
        reservaLiniaManual: "Tanto esta reserva como alguna de sus líneas han sido modificadas manualmente",
        altres: 'Otros',
    },
    recursos: {
        title: "Recursos",
        create: "Crear recurso",
        update: "Modificar recurso",
    },
    reservesDescompte: {
        title: "Descuentos de reserva",
        create: "Crear descuentos de reserva",
        update: "Modificar descuentos de reserva",
    },
    reservesLinia: {
        title: "Linias de reserva",
        create: "Crear linias de reserva",
        update: "Modificar linias de reserva",
    },
    requisitsRecurs: {
        title: "Requisitos de recursos",
        create: "Crear requisitos de recursos",
        update: "Modificar requisitos de recursos",
    },
    requerimentsRecurs: {
        title: "Requerimientos de recursos",
        create: "Crear requerimiento de recursos",
        update: "Modificar requerimiento de recursos",
    },
    users: {
        title: "Usuarios",
        create: "Crear usuario",
        update: "Modificar usuario",
        password: {
            title: "Cambiar contraseña",
            caption: "Rellenar sólo si se quiere modificar la contraseña del usuario",
        },
    },
    productes: {
        title: "Productos",
        create: "Crear producto",
        update: "Modificar producto",
    },
    agendesProducte: {
        title: "Disponibilidad del producto",
        create: "Crear disponibilidad del producto",
        update: "Modificar disponibilidad del producto",
        tab: {
            taula: 'Lista',
            calendari: 'Calendario',
        },
        actions: {
            planificar: {
                title: 'Planificar',
                tabForm: 'Datos generales',
            },
            seleccionar: 'Seleccionar todas las disponibilidades',
        },
        detall: {
            title: 'Ver detalle',
        },
        calendari: {
            switchProductes: "Filtrar por todos los productos",
        },
    },
    parametres: {
        title: 'Parámetros',
        titleMobil: 'Precio Cuevas para la aplicación móvil',
        titleComptabilitat: 'Parámetros para contabilidad',
    },
    informes: {
        title: 'Informes por zona y vendedor',
        create: 'Crear informe',
        update: 'Modificar informe',
        totals: {
            efectiu: 'Efectivo',
            targeta: 'Tarjeta',
            tiquets: 'Tiques',
            coves: 'Cuevas',
            total: 'Importe total',
            resultats: 'Resultados',
        },
    },
};

export default translationEs;
